// import { timeFormat } from '@/filters';
import Vue from 'vue'

import saveAs from 'file-saver';

// const Excel = require('exceljs');

import Excel from 'exceljs'

import {
	Loading,
	Notification,
	MessageBox
} from 'element-ui'; //项目已经全局引入element的话可以不单独引入

let loading //定义loading变量

function startLoading() { //使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		text: 'loading……',
		background: 'rgba(0, 0, 0, 0.5)'
	})
}

function endLoading() { //使用Element loading-close 方法
	loading.close()
}


let workbook;
let worksheet;

function init() {
	workbook = new Excel.Workbook();
	workbook.created = new Date();
	workbook.modified = new Date();
}

function initSheet(sheetName) {
	// 创建一个冻结了第一行和第一列的工作表
	// worksheet = workbook.addWorksheet('sheet1', {views:[{state: 'frozen', xSplit: 1, ySplit:1}]});
	worksheet = workbook.addWorksheet(sheetName, {
		views: [{
			state: 'frozen',
			ySplit: 1
		}]
	});

	// 将第2行设置为 Comic Sans。
	// worksheet.getRow(1).font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
	worksheet.getRow(1).font = {
		size: 14,
		bold: true
	};
}

// function setSheetExcelHead(columns){
// 	worksheet.columns = columns;
// }

function OneSheetExcelData(columns, Data, option) {
	// columns = columns.map((item) => {
	// 	return Object.assign({}, {
	// 			header: item.title,
	// 			width: item.types == 'image' ? 60 : (item.width ? item.width : 20),
	// 			// style: { font: { size: 14, bold: true } }
	// 		},
	// 		item
	// 	);
	// });
	// worksheet.columns = columns;
	
	let rowHeight = 20;
	if (option && option.height) {
		rowHeight = option.height;
	} else { //默认20
		rowHeight = 20;
	}

	for (let i = 0; i < Data.length; i++) {
		let list = [];
		let rowData = {};
		for (let j = 0; j < columns.length; j++) {
			if (columns[j].types == 'text' || columns[j].types == undefined) {
				rowData[columns[j].key] = Data[i][columns[j].key];
			} else if (columns[j].types == 'date') {
				// rowData[columns[j].key] = timeFormat(Data[i][columns[j].key]);
			} else if (columns[j].types == 'custom') {
				// console.log(Data[i]);
				// console.log(columns[j]);
				try {
					rowData[columns[j].key] = columns[j].formatter(Data[i]);
				} catch (err) {
					console.log(err);
				}
			} else if (columns[j].types == 'image') {
				let base64 = Data[i][columns[j].key];
				let img_width = Data[i]['ExImgWidth'];
				let img_height = Data[i]['ExImgHeight'];
				if (base64) {
					let imageId2 = workbook.addImage({
						base64: base64,
						extension: 'jpeg',
					});
					worksheet.addImage(imageId2, {
						tl: {
							col: 0.5 + j,
							row: 1.5 + i
						},
						ext: {
							width: img_width?img_width:376,
							height: img_height?img_height:142
						},
					});
				}

			}
		}
		// 添加一行数据
		list.push(rowData);
		worksheet.addRows(list);
		// 获取最后一行
		let row = worksheet.lastRow;
		// 设置特定的行高
		// if (option && option.height) {
		// 	row.height = option.height;
		// } else { //图片需要
		// 	row.height = 142;
		// }
		// if (option && option.height) {
		// 	row.height = option.height;
		// } else { //默认20
		// 	row.height = 20;
		// }
		row.height = rowHeight;
		row.commit();

		// 设置单元格样式
		for (let n = 0; n < columns.length; n++) {
			let dobCol = worksheet.getColumn(n + 1);
			dobCol.eachCell(function(cell, rowNumber) {
				cell.alignment = {
					vertical: 'middle',
					horizontal: 'center'
				};
			});
		}
	}
}

//合并单元格
//合并第二层
function MergeSheetCells(option,mergeDataRow) {
	// if(option.hasMerge){
		let startPosition = 1;//数据起始行索引
		let mergeData = [];
		option.mergeDataCol.forEach(item=>{//['H','I']，列数
			console.log('mergeDataRow',mergeDataRow);
			mergeDataRow.forEach((item2,index2)=>{//[1,2,2,1],二维数组长度
				// mergeData.push(item+item2+':'+);//[H1:H1,H2:H3,H4:H5,H6:H6]
				// if(item2>1){
					let startCell = '';
					if(0==index2){
						startCell = startPosition+1;
					} else {
						startCell = mergeData[mergeData.length-1].split(':')[1].replace(/[A-Z]/g,'');
						startCell = parseInt(startCell)+1;
					}
					
					let EndCell = '';
					if(0==index2){
						EndCell = parseInt(startPosition+item2);
					} else {
						EndCell = startCell+parseInt(item2-1);
					}
					
					if(startCell && EndCell){
						mergeData.push(item+startCell+':'+item+EndCell);
					}
				// }
				
			});
		});
		console.log('mergeData',mergeData);
		// return;
		mergeData.forEach(item=>{
			// worksheet.mergeCells('A1', 'E1');
			// worksheet.mergeCells(item);
			let colKeys = item.split(":");
			if(colKeys[0]!=colKeys[1]){//过滤掉同一个格子的合并
				worksheet.mergeCells(item);
			}
		})
	// }
	
}

//合并第三层
function MergeSheetCells2(option,mergeDataRow) {
	// if(option.hasMerge){
		let startPosition = 1;//数据起始行索引
		let mergeData = [];
		option.mergeDataCol2.forEach(item=>{//['H','I']，列数
			console.log('mergeDataRow2',mergeDataRow);
			mergeDataRow.forEach((item2,index2)=>{//[1,2,2,1],二维数组长度
				// mergeData.push(item+item2+':'+);//[H1:H1,H2:H3,H4:H5,H6:H6]
				// if(item2>1){
					let startCell = '';
					if(0==index2){
						startCell = startPosition+1;
					} else {
						startCell = mergeData[mergeData.length-1].split(':')[1].replace(/[A-Z]/g,'');
						startCell = parseInt(startCell)+1;
					}
					
					let EndCell = '';
					if(0==index2){
						EndCell = parseInt(startPosition+item2);
					} else {
						EndCell = startCell+parseInt(item2-1);
					}
					
					if(startCell && EndCell){
						mergeData.push(item+startCell+':'+item+EndCell);
					}
				// }
			});
		});
		console.log('mergeData2',mergeData);
		// return;
		mergeData.forEach(item=>{
			// worksheet.mergeCells('A1', 'E1');
			let colKeys = item.split(":");
			if(colKeys[0]!=colKeys[1]){//过滤掉同一个格子的合并
				worksheet.mergeCells(item);
			}
			
		})
	// }
	
}


//顶部有字段名称
function OneSheetExcel(columns, Data, option) {
	let columnsMap = columns.map((item) => {
		return Object.assign({}, {
				header: item.title,
				width: item.types == 'image' ? 60 : (item.width ? item.width : 20),
				// style: { font: { size: 14, bold: true } }
			},
			item
		);
	});
	worksheet.columns = columnsMap;
	OneSheetExcelData(columnsMap, Data, option)
}
//顶部没有字段名称
function OneSheetExcelNoHeader(columns, Data, option) {
	let columnsMap = columns.map((item) => {
		return Object.assign({}, {
				header: item.title,
				width: item.types == 'image' ? 60 : (item.width ? item.width : 20),
				// style: { font: { size: 14, bold: true } }
			},
			item
		);
	});
	worksheet.columns = columns.map(item => {
		return {
			key: item.key,
			width: item.types == 'image' ? 60 : (item.width ? item.width : 20),
		}
	});
	OneSheetExcelData(columnsMap, Data, option)
}

//添加一行数据，并设置字体
function AddFirstRowCountData(headerArr, columns) {

	// After columns definition

	// Insert an empty row at beginning
	// worksheet.spliceRows(1, 0, []) 

	// Set title
	// worksheet.getCell('A1').value = headerArr.join('-');
	// worksheet.getRow(1).values = headerArr;
	worksheet.mergeCells('A1', 'E1');
	worksheet.getCell('A1').value = headerArr.join('-');
	// worksheet.addRows(titleHeader);
	// Optional merge and styles
	// worksheet.mergeCells('A1:E1') 
	// worksheet.getCell('A1').alignment = { horizontal: 'center' } 

	/*Column headers*/
	let titleHeader = columns.map(item => item.title);
	// worksheet.addRows([...titleHeader]);
	worksheet.getRow(2).values = titleHeader;
	// let rowCount = worksheet.rowCount;
	for (let i = 0, ln = titleHeader.length; i < ln; i++) {
		worksheet.getRow(2).font = {
			size: 14,
			bold: true
		};
	}

	// worksheet.addRows([...values]);
	// // let rowCount = worksheet.rowCount;
	// for(let i=0,ln=values.length;i<ln;i++){
	// 	worksheet.getRow(rowNum).font = {//行数目加粗
	// 		size: 14,
	// 		bold: true
	// 	};
	// }

	// worksheet.mergeCells('A1','N1');
	// worksheet.getCell('A1').value = str;
	// worksheet.getCell('A1').font = {
	//   name: 'Arial Black',
	//   size: 14,
	//   bold: true
	// };
}

//账单用-间隔一行，添加底部一行数据，并设置字体
function AddLastRowCountData(values) {
	worksheet.addRows([
		[], ...values
	]);
	let rowCount = worksheet.rowCount;
	for (let i = 0, ln = values.length; i < ln; i++) {
		worksheet.getRow(rowCount - i).font = {
			size: 14,
			bold: true
		};
	}
}

//导出
function expExcelFile(fileName) {
	console.log("workbook", workbook);
	workbook.xlsx.writeBuffer().then((buffer) => {
		let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
		saveAs(
			new Blob([buffer], {
				type: 'application/octet-stream',
			}),
			fileName + '_' + dateStr + '.' + 'xlsx'
		);
	});
}
/**
 * 多页签导出
 * @param {sheetName,columns,Data,lastData} allSheet 
 * @param {*} fileName 
 * @param {*} option 
 *
 */
function exportExcelForMultiSheet(allSheet, fileName, option,mergeDataOption) {
	startLoading();

	init()
	for (let aln = 0; aln < allSheet.length; aln++) {
		let One_Sheet = allSheet[aln];
		initSheet(One_Sheet.sheetName);
		let columns = One_Sheet.columns;
		let Data = One_Sheet.Data;
		OneSheetExcel(columns, Data, option);
		if (One_Sheet.lastData && One_Sheet.lastData.length > 0) {
			AddLastRowCountData(One_Sheet.lastData);
		}
		//合并单元格
		if(mergeDataOption && mergeDataOption.hasMerge){//合并第二层
			MergeSheetCells(option,mergeDataOption.mergeDataRow);
			if(!!mergeDataOption.mergeDataRow2 && mergeDataOption.mergeDataRow2.length>0){//合并第三层
				MergeSheetCells2(option,mergeDataOption.mergeDataRow2);
			}
		}
	}
	//生成文件流导出
	expExcelFile(fileName);

	endLoading();
}
/**
 * 多页签导出,有头部
 *
 */
function exportExcelForMultiSheetHasHeader(allSheet, fileName, option) {
	startLoading();

	init()
	for (let aln = 0; aln < allSheet.length; aln++) {
		let One_Sheet = allSheet[aln];
		initSheet(One_Sheet.sheetName);
		let columns = One_Sheet.columns;
		let Data = One_Sheet.Data;
		if (One_Sheet.firstData && One_Sheet.firstData.length > 0) {
			AddFirstRowCountData(One_Sheet.firstData, columns);
		}
		OneSheetExcelNoHeader(columns, Data, option);
		if (One_Sheet.lastData && One_Sheet.lastData.length > 0) {
			AddLastRowCountData(One_Sheet.lastData);
		}
	}
	//生成文件流导出
	expExcelFile(fileName);

	endLoading();
}

//单个导出
function exportExcel(columns, Data, fileName, option) {
	startLoading();

	init();
	initSheet("sheet1");
	OneSheetExcel(columns, Data, option);
	
	
	// AddLastRowData();

	expExcelFile(fileName);

	endLoading();
}

//账单导出
// function exportExcelForBill(allSheet, fileName, option) {
// 	init()
// 	for (let aln = 0; aln < allSheet.length; aln++) {
// 		let One_Sheet = allSheet[aln];
// 		initSheet(One_Sheet.sheetName);
// 		let columns = One_Sheet.columns;
// 		let Data = One_Sheet.Data;
// 		OneSheetExcel(columns, Data,option);
// 	}
// 	//生成文件流导出
// 	expExcelFile(fileName);
// }


// function exportExcelByDomBase64Img(columns_p, Data_p,fileName,domID){
// 	let columns = columns_p;
// 	let Data = Data_p;

// 	// let header_dom = JQ(domID).find(".el-table__header");

// 	// let td_list = header_dom.find("thead tr:eq(0) th");
// 	// td_list.forEach(item=>{
// 	// 	columns.push(item.find(".cell").text());
// 	// })

// 	let data_dom = JQ(domID).find(".el-table__body");
// 	let obj_key_img = {};
// 	// console.log(data_dom.find("tr"));
// 	data_dom.find("tr").each((index, domEle)=>{
// 		// console.log("index",index);
// 		let imgObj = JQ(domEle).find("td .cell img");
// 		// console.log("imgObj",imgObj);
// 		if(imgObj){
// 			obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
// 		}
// 	})
// 	// console.log("obj_key_img",obj_key_img);
// 	Data_p.forEach(item=>{
// 		item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
// 	})

// 	console.log("columns",columns);
// 	console.log("Data",Data);
// 	// return;
// 	exportExcel(columns, Data, fileName);
// }

export default {
	exportExcelForMultiSheet,
	exportExcelForMultiSheetHasHeader,
	exportExcel,
	// exportExcelByDomBase64Img
};
